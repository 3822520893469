<template>
    <div>
        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>

        <div>
            <CSpinner v-if="loader" class="spinner" />
        </div>
        
        <!-- The expanded graph view -->
        <div>
            <CModal
                :title="graph.data['name']"
                :show.sync="graphModal"
                size="xl"
            >
                <div v-if="graph.npk === false">
                    <apexchart
                        type="line"
                        :options="getChartOptions(graph.data['date'])"
                        :series="getOptions(graph.data['data'], [], null, null)"
                    ></apexchart>
                </div>

                <div v-else>
                    <apexchart
                        type="line"
                        :options="getChartOptions(graph.data['date'])"
                        :series="
                            getOptions(
                                graph.data['data'],
                                graph.data['data2'],
                                graph.data['max'],
                                graph.data['min']
                            )
                        "
                    ></apexchart>
                </div>

                <template #footer>
                    <CButton @click="closeModal()" color="danger"
                        >Close</CButton
                    >
                </template>
            </CModal>
        </div>

        <div>
            <CCard>
                <CRow class="ml-1 mt-4">
                    <CCol>
                        <CInput
                            label="From"
                            type="date"
                            v-model="startTime"
                            horizontal
                        />
                    </CCol>

                    <CCol>
                        <CInput
                            label="To"
                            type="date"
                            v-model="endTime"
                            horizontal
                        />
                    </CCol>

                    <CCol col="6" sm="4" md="2" xl class="mb-xl-0">
                        <CButton
                            color="primary"
                            shape="pill"
                            @click="getGraphAPI()"
                        >
                            Search
                        </CButton>
                    </CCol>
                </CRow>
            </CCard>
        </div>

        <div v-if="fetch" class="row d-flex">
                <div class="col-4" :key="index" v-for="(data, index) in datas">
                    <CCard class="graphBody">
                        <!-- If the API call is returning no data -->
                        <div v-if="data['Exists'] == false">
                            <CCardHeader>
                                <strong>{{ data["name"] }}</strong>
                            </CCardHeader>

                            <CCardBody>
                                <div
                                    class="
                                        apiError
                                        align-items-center
                                        d-flex
                                        justify-content-center
                                    "
                                >
                                    <strong>{{ data["data"] }}</strong>
                                </div>
                            </CCardBody>
                        </div>

                        <!-- If the API call is returning data -->
                        <div v-else>
                            <CCardHeader class="cardHeader">
                                <CRow>
                                    <div class="col-8">
                                        <strong>{{ data["name"] }}</strong>
                                    </div>

                                    <div class="col-4">
                                        <CButton
                                            type="submit"
                                            size="sm"
                                            color="primary"
                                            class="float-right"
                                            @click="setGraph(data)"
                                        >
                                            See more
                                        </CButton>
                                    </div>
                                </CRow>
                            </CCardHeader>

                            <div v-if="data['NPK'] == true">
                                    <CChartLine
                                        :datasets="
                                            defaultDatasets(
                                                data['data'],
                                                data['data2'],
                                                data['max'],
                                                data['min'],
                                            )
                                        "
                                        :options="
                                            defaultOptions(
                                                getMax(
                                                    data['data'],
                                                    data['max']
                                                )
                                            )
                                        "
                                        :labels="data['date']"
                                        style="height: 300px; margin-top: 20px"
                                    />
                            </div>

                            <div v-else>
                                <CChartLine
                                    :datasets="
                                        defaultDatasets(
                                            data['data'],
                                            [],
                                            null,
                                            null,
                                        )
                                    "
                                    :options="
                                        defaultOptions(getMax(data['data']))
                                    "
                                    :labels="data['date']"
                                    style="height: 300px; margin-top: 20px"
                                />
                            </div>
                        </div>
                    </CCard>
                </div>
            </div>

    </div>
</template>


<script>
import axios from "axios";
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";


const URL = axios.defaults.baseURL;
const sensorDataUrl = URL + `${"profile/farmer/sensor/data/"}`;
const graphUrl = URL + `${"profile/graph/initial/"}`;

export default {
    name: "FarmerDashboard",
    components: { CChartLine },
    data() {
        return {
            loader: false,
            errorMessage: "",
            checkError: false,
            graphModal: false,
            datas: [],
            startTime: "",
            endTime: "",
            graph: {
                data: {
                    name: "",
                    data: [0, 1],
                    data2: [0, 1],
                    date: "",
                    NPK: false,
                    max: 100,
                    min: 0,
                },
            },
            fetch: false,
        };
    },
    methods: {
        getMax(data, max) {
            if (max == null) {
                let maxVal = Math.max.apply(Math, data);
                return maxVal + 0.5;
            } else {
                let maxVal = Math.max.apply(Math, data);
                let maxValue = Math.max(maxVal, max);
                return maxValue + 0.5;
            }
        },

        getArray(length, value) {
            var data1 = [];
            for (let i = 0; i < length; i++) {
                data1.push(value);
            }
            return data1;
        },

        setGraph(data) {
            this.graph.data = data;
            this.graphModal = true;
        },

        closeFile() {
            this.graphModal = false;
        },

        closeModal() {
            this.graphModal = false;
            this.graph = {
                data: {
                    name: "",
                    data: [0, 1],
                    data2: [0, 1],
                    date: "",
                    NPK: false,
                    max: 100,
                    min: 0,
                },
            };
        },

        getGraph() {
            this.datas = [];
            this.loader = true;
            this.axios
                .get(graphUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    this.loader = false;
                    this.datas = response.data.context;
                    this.fetch = true;
                })
                .catch((error) => {
                    this.loader = false;
                    this.fetch = false;
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },

        getGraphAPI() {
            this.fetch = false;
            this.loader = true;
            this.axios
                .get(sensorDataUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: {
                        start_date: this.startTime,
                        end_date: this.endTime,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context)
                    this.datas = response.data.context;
                    this.fetch = true;
                    this.loader = false;
                })
                .catch((error) => {
                    this.datas = [];
                    this.fetch = false;
                    this.loader = false;
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        // console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        getChartOptions(date) {
            return {
                chart: {
                    id: "vuechart-example",
                },
                xaxis: {
                    categories: date,
                },
                colors: ["#4dbd74", "#9A7B4F", "#FF0000", "#000000"],
            };
        },

        getOptions(data, data2, max, min) {
            if (max == null && min == null) {
                return [
                    {
                        name: "Value",
                        data: data,
                    },
                ];
            } else {
                return [
                    {
                        name: "Surface",
                        data: data,
                    },
                    {
                        name: "Sub Surface",
                        data: data2,
                    },

                    {
                        name: "Max",
                        data: this.getArray(data.length, max),
                    },
                    {
                        name: "Min",
                        data: this.getArray(data.length, min),
                    },
                ];
            }
        },

        defaultDatasets(data, data2, max, min) {
            var green = getStyle("success2") || "#4dbd74";
            var brown = getStyle("success2") || "#9A7B4F";
            var brandInfo = getStyle("info") || "#20a8d8";
            var brandMax = "#FF0000";
            var brandMin = "#000000";
            var brandTransparent = "#ffffff";

            if (max == null && min == null) {
                return [
                    {
                        label: "Value: ",
                        backgroundColor: hexToRgba(brandTransparent, 10),
                        borderColor: green,
                        pointHoverBackgroundColor: green,
                        borderWidth: 2,
                        data: data,
                    },
                ];
            } else {
                return [
                    {
                        label: "Surface: ",
                        backgroundColor: hexToRgba(brandTransparent, 10),
                        borderColor: green,
                        pointHoverBackgroundColor: green,
                        borderWidth: 2,
                        data: data,
                    },
                    {
                        label: "Sub Surface: ",
                        backgroundColor: hexToRgba(brandTransparent, 10),
                        borderColor: brown,
                        pointHoverBackgroundColor: brown,
                        borderWidth: 2,
                        data: data2,
                    },
                    {
                        label: "Max: ",
                        backgroundColor: hexToRgba(brandTransparent, 10),
                        borderColor: brandMax,
                        pointHoverBackgroundColor: brandMax,
                        borderWidth: 2,
                        data: this.getArray(data.length, max),
                    },
                    {
                        label: "Min: ",
                        backgroundColor: hexToRgba(brandTransparent, 10),
                        borderColor: brandMin,
                        pointHoverBackgroundColor: brandMin,
                        borderWidth: 2,
                        data: this.getArray(data.length, min),
                    },
                ];
            }
        },

        defaultOptions(max) {
            return {
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                stepSize: Math.ceil(10 / 5),
                                max: max,
                            },
                            gridLines: {
                                display: true,
                            },
                        },
                    ],
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            };
        },
    },

    watch: {
        checkError(newValue) {
            if (newValue) setTimeout(() => (this.checkError = false), 2000);
        },
    },

    beforeMount() {
        this.getGraph();
        // this.getGraphAPI();
    },
};
</script>